import { useAuth } from "@features/auth/state/use-auth";
import { changeLanguageHandler } from "@features/i18n";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";

import { useTranslation } from "react-i18next";

export const Header = () => {
  const { i18n, t } = useTranslation();
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [langMenuActive, setLangMenuActive] = useState(false);
  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-transparent shadow-lg"
    >
      <div className="logo">
        <a
          target="_blank"
          href="https://olky.eu/"
          className="logo me-auto"
          rel="noreferrer"
        >
          <img src="/images/logo-footer.png" className="img-fluid" alt="OLKY" />
        </a>
      </div>

      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo"></div>
        <nav
          id="navbar"
          className={"navbar " + (menuOpen ? "navbar-mobile" : "")}
        >
          <ul className="ml-auto nav-elements">
            <li className="nav-item">
              <a
                className="nav-link text-white"
                target="_blank"
                href="https://olky.eu/fr/universe/olkypay/"
                rel="noreferrer"
              >
                OlkyPay
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white"
                target="_blank"
                href="https://olky.eu/fr/universe/olkypass/"
                rel="noreferrer"
              >
                OlkyPass
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white"
                target="_blank"
                href="https://olky.eu/fr/universe/olkywallet/"
                rel="noreferrer"
              >
                OlkyWallet
              </a>
            </li>

            <li className="dropdown nav-item sm:ml-4 ml-2 sm:mb-2 sm:mt-0 mb-8 mt-2">
              <span
                className="text-white cursor-pointer h-16"
                onClick={() => setLangMenuActive(!langMenuActive)}
              >
                {i18n.language === "fr" && (
                  <img src="/images/langFrIcon.png" alt="FR" />
                )}
                {i18n.language !== "fr" && (
                  <img src="/images/langEnIcon.png" alt="EN" />
                )}
              </span>
              <ul
                style={{ width: "60px" }}
                className={langMenuActive ? "dropdown-active" : ""}
              >
                <li className="lang cursor-pointer p-2 px-4">
                  <span onClick={() => changeLanguageHandler(i18n, "fr")}>
                    <img src="/images/langFrIcon.png" alt="FR" />
                  </span>
                </li>
                <li className="lang cursor-pointer p-2 px-4">
                  <span onClick={() => changeLanguageHandler(i18n, "en")}>
                    <img src="/images/langEnIcon.png" alt="EN" />
                  </span>
                </li>
              </ul>
            </li>

            {user?.id && (
              <span
                onClick={() => logout()}
                className="ml-2 py-2 px-4 text-white border-2 border-solid rounded-full cursor-pointer hover:opacity-75"
              >
                <span className="d-md-inline">{t("account.logout")}</span>
              </span>
            )}
          </ul>
          {!menuOpen && (
            <MenuIcon
              className="mr-2 h-8 w-8 mobile-nav-toggle"
              onClick={() => setMenuOpen(true)}
            />
          )}
          {menuOpen && (
            <XIcon
              className="mx-1 my-2 h-8 w-8 mobile-nav-toggle"
              onClick={() => setMenuOpen(false)}
            />
          )}
        </nav>
      </div>
    </header>
  );
};
