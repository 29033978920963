import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info } from "@atoms/text";
import { MovementType } from "@features/register/types";
import { formatTime } from "@features/utils/dates";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";

export const TransactionModalAtom = atom<null | MovementType>({
  key: "TransactionModalAtom",
  default: null,
});

export const TransactionModal = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useRecoilState(TransactionModalAtom);
  const movement = status!;
  return (
    <Modal open={!!status} onClose={() => setStatus(null)}>
      {!!status && (
        <ModalContent
          title={t("dashboard.operation_types." + movement.Type?._text)}
        >
          <div className="flex flex-row rounded-sm items-center -mt-2 mb-4">
            <div className="shrink-0 rounded-full h-14 w-14 bg-gray-100 mr-2 flex items-center justify-center">
              <img
                alt="KYC"
                src="/images/kyc-crypto-icon.png"
                className="h-10 w-10 inline-block"
              />
            </div>
            <div className="shrink-0 sm:w-40">
              <div>
                {(
                  parseFloat(
                    movement?.NumberInt?._text +
                      "." +
                      movement?.NumberDec?._text
                  ) / 100
                ).toLocaleString(navigator.language, {
                  useGrouping: true,
                  minimumFractionDigits: 2,
                })}{" "}
                <Info noColor className="opacity-75">
                  EUR
                </Info>
              </div>
              <Info>{formatTime(movement.Date?._text || "")}</Info>
            </div>
          </div>

          <div className="mt-2">
            <Info>{t("dashboard.details.counterparty")}</Info>
            <div>{movement.Counterparty?._text}</div>
          </div>

          <div className="mt-2">
            <Info>{t("dashboard.details.partner")}</Info>
            <div>{movement.RefPartner?._text}</div>
          </div>

          <div className="mt-2">
            <Info>{t("dashboard.details.type")}</Info>
            <div>{t("dashboard.operation_types." + movement.Type?._text)}</div>
          </div>
        </ModalContent>
      )}
    </Modal>
  );
};
