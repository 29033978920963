import { ROUTES } from "@features/routes";
import { Modals } from "@views/modals";
import { Trans } from "react-i18next";
import { Outlet, Route } from "react-router-dom";
import { Dashboard } from "./dashboard";

export const ClientRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path={ROUTES.Dashboard} element={<Dashboard />} />
    </Route>
  );
};

export const Layout = () => {
  return (
    <div className="flex w-full grow flex-col h-full relative">
      <div className="grow flex min-h-0">
        <div className="grow min-h-0 lg:px-0">
          <div className="text-white text-3xl top-32 absolute px-4 lg:px-0 py-12 max-w-5xl mx-auto w-full left-0 right-0">
            <div className="max-w-lg">
              <Trans i18nKey="dashboard.title" components={[<b />, <b />]} />
            </div>
          </div>

          <Outlet />
        </div>
      </div>
      <Modals />
    </div>
  );
};
