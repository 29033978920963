import { ReactNode } from "react";
import { Header } from "./header";

export const Layout = (props: { children: ReactNode }) => {
  return (
    <>
      <Header />

      <div
        className="bg-cover bg-center absolute top-0 left-0 right-0 z-0 bg-blue-500"
        style={{
          backgroundImage: "url(/images/fond/texture-1.png)",
          height: "360px",
        }}
      >
        <div
          className="bg-cover bg-right-bottom absolute right-0 left-0 z-0"
          style={{
            backgroundImage: "url(/images/fond/waves.png)",
            height: "40px",
            top: "360px",
          }}
        ></div>
        <div
          className="bg-contain bg-no-repeat bg-right-bottom absolute right-0 z-0"
          style={{
            backgroundImage: "url(/images/fond/texture-2.png)",
            height: "300px",
            width: "300px",
            top: "100px",
          }}
        ></div>
      </div>

      <main id="main" className="bg-slate-50" style={{ minHeight: "100vh" }}>
        {props.children}
      </main>
    </>
  );
};
