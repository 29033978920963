import { Button } from "@atoms/button/button";
import { Loader } from "@atoms/loader";
import { Base, Info, InfoSmall, Section, Title } from "@atoms/text";
import { useRegister } from "@features/register/state/use-register";
import { MovementType } from "@features/register/types";
import { formatTime } from "@features/utils/dates";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { Page } from "../../_layout/page";
import { TransactionModalAtom } from "./transaction-modal";

export const Dashboard = () => {
  const { register, loading, loadMore } = useRegister();
  const { t } = useTranslation();

  return (
    <Page>
      <Title noColor className="text-center">
        {t("dashboard.balance")}
      </Title>

      {!register?.Token && (
        <div className="flex items-center justify-center h-40 w-full">
          <Loader />
        </div>
      )}
      {!!register?.Token && (
        <>
          <div className="text-center mt-3">
            <Title noColor>
              <div
                className={
                  "flex justify-center items-center " +
                  (parseInt(register?.EndDateBalanceInt?._text || "0") <= 0
                    ? "text-red-400 "
                    : "text-blue-500")
                }
              >
                <span>
                  {(
                    parseFloat(register?.EndDateBalanceInt?._text + "") / 100
                  ).toLocaleString(navigator.language, {
                    useGrouping: true,
                    minimumFractionDigits: 2,
                  })}{" "}
                  <Base noColor>EUR</Base>
                </span>
              </div>
            </Title>
            <InfoSmall className="mt-4">
              {t("dashboard.updated", { to: formatTime(register.to) })}
            </InfoSmall>
          </div>

          <br />
          <div className="mx-auto max-w-xl">
            <Section>{t("dashboard.last_operations")}</Section>

            <div className="mt-2">
              {!(register?.Movements?.Detail || [])?.length && (
                <div className="p-4 text-center w-full">
                  {t("dashboard.no_operations")}
                </div>
              )}
              {(register?.Movements?.Detail || [])?.map((movement) => {
                return <Movement movement={movement} />;
              })}
            </div>
          </div>

          <div className="w-full text-center mt-4">
            <Info>
              {t("dashboard.last_operations_info", {
                from: formatTime(register.from),
              })}
            </Info>
            <br />
            <Button
              loading={loading}
              theme="outlined"
              size="sm"
              className="mt-2"
              onClick={() => {
                loadMore();
              }}
            >
              {t("dashboard.see_more")}
            </Button>
          </div>
        </>
      )}
    </Page>
  );
};

export const Movement = ({ movement }: { movement: MovementType }) => {
  const { t } = useTranslation();
  const openDetails = useSetRecoilState(TransactionModalAtom);

  const negativeFlow =
    parseInt(movement?.NumberInt?._text || "0") >= 0 &&
    movement?.FlowCredit?._text === "F";

  if (negativeFlow) {
    movement.NumberInt._text = "-" + movement?.NumberInt?._text;
  }

  const buildName = negativeFlow
    ? (movement.Counterparty?._text?.includes(movement.RefPartner?._text || "")
        ? movement.RefPartner?._text
        : (movement.Counterparty?._text || "").split(" ").slice(1).join(" ")
      )?.trim()
    : t("dashboard.operation_modal_transac." + movement?.ModalTransac?._text) ||
      t("dashboard.operation_modal_transac.30");

  return (
    <div
      className="flex flex-row bg-gray-50 hover:bg-gray-100 p-4 pl-2 rounded-sm items-center cursor-pointer"
      onClick={() => openDetails(movement)}
    >
      <div className="shrink-0 rounded-full h-14 w-14 bg-gray-100 mr-2 flex items-center justify-center">
        <img
          alt="KYC"
          src="/images/kyc-crypto-icon.png"
          className="h-10 w-10 inline-block"
        />
      </div>
      <div className="shrink-0 sm:w-40">
        <div>
          {t("dashboard.operation_types." + movement.Type?._text)}
          {buildName ? ` - ${buildName}` : ""}
        </div>
        <Info>{formatTime(movement.Date?._text || "")}</Info>
      </div>
      <div className="grow" />
      <div className="ml-4">
        <div
          className={
            "w-full justify-end flex items-center " +
            (parseInt(movement?.NumberInt?._text || "0") <= 0
              ? "text-red-400 "
              : "")
          }
        >
          <span>
            {(
              parseFloat(
                movement?.NumberInt?._text + "." + movement?.NumberDec?._text
              ) / 100
            ).toLocaleString(navigator.language, {
              useGrouping: true,
              minimumFractionDigits: 2,
            })}{" "}
            <Info noColor className="opacity-75">
              EUR
            </Info>
          </span>
        </div>
      </div>
    </div>
  );
};
