import { TransactionModal } from "./client/dashboard/transaction-modal";

export const Modals = () => {
  return (
    <>
      {" "}
      <TransactionModal />
    </>
  );
};
