import _ from "lodash";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "primary" | "secondary" | "danger" | "default" | "outlined";
  size?: "md" | "lg" | "xl" | "sm";
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const Button = (props: ButtonProps) => {
  const disabled = props.disabled || props.loading;

  let colors =
    "text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-800 border-transparent drop-shadow-sm	";

  if (props.theme === "secondary")
    colors =
      "text-blue-500 bg-blue-100 hover:bg-blue-200 active:bg-blue-300 border-transparent ";

  if (props.theme === "danger")
    colors =
      "text-white bg-rose-500 hover:bg-rose-600 active:bg-rose-700 border-transparent ";

  if (props.theme === "default")
    colors =
      "text-black bg-white border-gray-200 hover:bg-gray-50 active:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 dark:active:bg-gray-700 dark:text-white dark:border-gray-900";

  if (props.theme === "outlined")
    colors =
      "text-blue-500 bg-white dark:bg-gray-900 dark:hover:bg-gray-800 dark:active:bg-gray-900 hover:bg-gray-50 active:bg-gray-200 !border-blue-500 hover:opacity-75 border-solid border-2 ";

  if (disabled) colors += " opacity-50 pointer-events-none";

  let className = colors;

  if (props.size === "xl") className = className + " text-base h-14 px-12 ";
  else if (props.size === "lg") className = className + " text-base h-11 px-6 ";
  else if (props.size === "sm")
    className = className + " px-4 text-sm h-8 px-3";
  else className = className + " px-4 text-base h-9";

  if (!props.children) {
    if (props.size === "lg")
      className = className + " w-11 !p-0 justify-center";
    else if (props.size === "sm")
      className = className + " w-7 !p-0 justify-center";
    else className = className + " w-9 !p-0 justify-center";
  }

  return (
    <button
      type="button"
      className={
        "inline-flex items-center justify-center py-2 border text-sm font-medium rounded focus:outline-none " +
        className +
        " " +
        props.className
      }
      disabled={disabled}
      {..._.omit(props, "loading", "children", "className")}
    >
      {props.loading && (
        <>
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>{" "}
        </>
      )}
      {props.children}
    </button>
  );
};
