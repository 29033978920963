import i18n, { i18n as i18nType } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const languageDefault = "en";
const languageAvailable = ["fr", "en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languageDefault,
    supportedLngs: languageAvailable,
    backend: {
      loadPath: "/locales/{{lng}}.json?v=" + new Date().getDate(),
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

const navLang =
  window.localStorage.getItem("language") ||
  navigator?.language.split("-")[0].toLocaleLowerCase();
if (languageAvailable.includes(navLang)) {
  i18n.changeLanguage(navLang);
}

export const changeLanguageHandler = (i18n: i18nType, lang: string) => {
  window.localStorage.setItem("language", lang);
  if (lang === navLang) window.localStorage.removeItem("language");
  i18n.changeLanguage(lang);
};

export default i18n;
